window.loading_screen.finish();

angular.module('fingerink', [
    'templates-app',
    'ngAnimate',
    'ui.router',
    'ui.bootstrap',
    'pascalprecht.translate',
    'ui.calendar',
    'ngSanitize',
    'ngDragDrop',
    'signature',
    'ngMaterial',
    //'chart.js',
    'ui.bootstrap.contextMenu',
    'lk-google-picker',
    'one-drive-picker',
    //'ckeditor',
    //'datatables',
    'materialCalendar',
    //'rzModule',
    'ngJustGage'
    //'webcam'
]).config(function ($stateProvider, $urlRouterProvider, $locationProvider, $translateProvider, lkGoogleSettingsProvider, OneDriveSettingsProvider, $mdIconProvider, $$mdSvgRegistry, $httpProvider) {
    $urlRouterProvider.otherwise('');
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });

    $httpProvider.interceptors.push('authInterceptor');

    var locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);

    // Add default icons from angular material
    $mdIconProvider.icon('md-tabs-arrow', $$mdSvgRegistry.mdTabsArrow);

    $translateProvider.translations('en', EnglishTraslations);
    $translateProvider.translations('es', SpanishTraslations);
    $translateProvider.registerAvailableLanguageKeys(['en', 'es'], {
        'en_*': 'en',
        'es_*': 'es',
        '*': 'en'
    });
    $translateProvider.determinePreferredLanguage();
    $translateProvider.useSanitizeValueStrategy('escape');

    lkGoogleSettingsProvider.configure({
        apiKey: 'd9ktaRDjvnt22D7CaOy1CARu',// '0kzE3W3f-UufSwwnB9TLRC6i',
        clientId: '731033977125-csb5cci7ll68fqu3ddkfsm2u6h6dm8ct.apps.googleusercontent.com',// '244498974485-pbsju0228c1q9nci0j93osh2mbj0tftu.apps.googleusercontent.com',
        scopes: ['https://www.googleapis.com/auth/drive'],
        locale: 'en',
        features: [],
        views: ['DocsView(google.picker.ViewId.DOCUMENTS)'/*, 'DocsView(google.picker.ViewId.PDFS)'*/]
    });

    OneDriveSettingsProvider.configure({
        clientId: '9aeab319-1369-4235-a55a-18856368ae35', // One Drive Id 32 chars 
        action: 'download', // "share | download | query 
        openInNewWindow: true,
        multiSelect: false, //one drive multiselect 
        advanced: {
            filter: ".pdf"
        }
    });
})

    .controller('AppCtrl', function ($rootScope, $scope, $location, $transitions, $window, $state, $timeout) {
        $transitions.onError({}, function (transition) {
            if (transition.error().detail === "login") {
                $state.go("public.login");
            }
        });


        $transitions.onSuccess({},  transition =>{
            if (FingerinkEnvironmentAngular.trackGoogleFacebookTags) {
                $timeout( ()=>$window.ga('send', 'pageview', $location.url()));
            }

            if (transition && transition.to() && transition.to().data && transition.to().data.pageTitle) {
                $window.document.title = transition.to().data.pageTitle + ' - Fingerink';
            } else {
                $window.document.title = 'Fingerink';
            }
        });

        $rootScope.dominio = $location.host().substring($location.host().indexOf('.') + 1);
        $scope.FingerinkEnvironmentAngular = FingerinkEnvironmentAngular;

        function checkMobileTemplate() {
            var WinWidth = window.innerWidth || Math.max(document.documentElement.clientWidth, document.body.clientWidth);
            var WinHeight = window.innerHeight || Math.max(document.documentElement.clientHeight, document.body.clientHeight);

            if (WinWidth < 769) {
                $rootScope.includeMobileTemplate = true;
            } else {
                $rootScope.includeMobileTemplate = false;
            }
            $rootScope.pageWrapperMinHeight = WinHeight - 60;

            $rootScope.mainWrapperMinHeight = $rootScope.pageWrapperMinHeight;

            if (angular.element("#alertsHeader") && angular.element("#alertsHeader")[0] && angular.element("#alertsHeader")[0].offsetHeight) {
                $rootScope.mainWrapperMinHeight -= angular.element("#alertsHeader")[0].offsetHeight;
            }


        }
        checkMobileTemplate();


        angular.element($window).on('resize', function () {
            checkMobileTemplate();
            $scope.$digest();
        });
    });



